/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
//import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {currentUser?.roles?.includes('SHORTENER:READ') ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Shortener Managements
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/apps/shortener/links'
            icon='/media/icons/duotune/general/gen059.svg'
            title='Links'
            fontIcon='bi-layers'
          />
          {/* <AsideMenuItem
            to='/apps/shortener/stats'
            icon='/media/icons/duotune/abstract/abs046.svg'
            title='Stats'
            fontIcon='bi-layers'
          /> */}
        </>
      ) : (
        ''
      )}

      {currentUser?.roles?.includes('SPORTSMARKETS:READ') ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Sports Markets Managements
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/apps/sports/markets'
            icon='/media/icons/duotune/general/gen059.svg'
            title='Sports Markets'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/apps/stuck/match'
            icon='/media/icons/duotune/general/gen059.svg'
            title='Stuck Sports'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        ''
      )}

      {currentUser?.roles?.includes('MATCHES:READ') ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Matches Managements
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/apps/matches/featured'
            icon='/media/icons/duotune/general/gen059.svg'
            title='Feature Matches'
            fontIcon='bi-layers'
          />
          {/* <AsideMenuItem
            to='/apps/stuck/match'
            icon='/media/icons/duotune/general/gen059.svg'
            title='Stuck Sports'
            fontIcon='bi-layers'
          /> */}
        </>
      ) : (
        ''
      )}

      {currentUser?.roles?.includes('CHAT:READ') ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Chat Managements
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/apps/chat/history'
            icon='/media/icons/duotune/general/gen013.svg'
            title='Chat History'
            fontIcon='bi-clock-history'
          />
          <AsideMenuItem
            to='/apps/chat/users'
            icon='/media/icons/duotune/communication/com014.svg'
            title='Chat Users'
            fontIcon='bi-users'
          />
          <AsideMenuItem
            to='/apps/chat/channels'
            icon='/media/icons/duotune/communication/com014.svg'
            title='Chat Channels'
            fontIcon='bi-grip-vertical'
          />
        </>
      ) : (
        ''
      )}

      {currentUser?.roles?.includes('USERS:READ') ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Admin Managements
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/apps/user-management/users'
            icon='/media/icons/duotune/communication/com014.svg'
            title='User management'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        ''
      )}
    </>
  )
}
